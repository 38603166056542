import { Container, Graphics, Sprite, Text, useTick } from '@inlet/react-pixi';
import { TextStyle } from '@pixi/text';
import { filters } from 'pixi.js';
import { useCallback, useState } from 'react';
import { ColorScheme } from '../../color-scheme';
import { SkillListComponent } from '../../components/skill-list-component';
import creative_icon from './CreativeIcon.png'
import dreamer_icon from './DreamerIcon.png'
import team_icon from './TeamIcon.png'
import hardskillstxt from "./../../assets/skills/hardskill.json";
import softkillstxt from "./../../assets/skills/softskill.json";
import { AboutmeText } from "../../assets/aboutme";
import { ImageBadgeComponent } from '../../components/image-badge-component';
import { ArrowButtonComponent } from '../../components/arrow-button-component';
import bgPic from './20220604_160401.jpg';

type Prop = {
  y: number
}

export const AboutMe = ({ y }: Prop) => {

  const [mask, setMask] = useState(null);
  const [skillPanel_x, setSkillPanel_x] = useState(window.innerWidth / 3);
  const [skillPanelContent_x, setSkillPanelContent_x] = useState(0);
  const [skillPanel_open, setSkillPanel_open] = useState(false);
  const [arrowScale, setArrowScale] = useState(0);
  const [pictureBrightness, setPictureBrightness] = useState(0);
  let targetSkillPanel_x = window.innerWidth / 3;
  let targetArrowScale = 1;
  let targetPictureBrightness = 0.7;

  const textsize = (30 / 1440) * window.innerHeight;
  const badge_skill_size = (140 / 1440) * window.innerHeight;
  const badge_skill_y = (300 / 1440) * window.innerHeight;
  const badge_imagesize = (0.13 / 1440) * window.innerHeight;

  const skillRHeight = (45 / 1440) * window.innerHeight;
  const skillHeight = (hardskillstxt.length * (skillRHeight + 2)) + (softkillstxt.length * (skillRHeight + 2))
  const initSkillY = window.innerHeight / 2 + window.innerHeight / 6;
  let skillY = initSkillY;
  if (window.innerHeight - initSkillY < (skillHeight / 2)) {
    skillY = initSkillY - (((hardskillstxt.length * (skillRHeight + 2))) - (window.innerHeight - initSkillY))
  }
  const skillY2 = skillY + (skillHeight / 2);

  const textX = (window.innerWidth / 2) + (window.innerWidth / 8);
  const textY = (window.innerHeight / 2);

  const bgPic_x = (window.innerWidth / 2);
  const bgPic_y = (window.innerHeight / 2);
  const bgPic_scalex = (window.innerHeight / 1440) + 0.2;
  const bgPic_scaley = (window.innerHeight / 1440) + 0.2;

  const colorFilter = new filters.ColorMatrixFilter();
  //colorFilter.blackAndWhite(false);
  colorFilter.tint(ColorScheme.HighLight_alt,true);
  //colorFilter.blackAndWhite(true);
  colorFilter.brightness(pictureBrightness, true);


  const draw = useCallback(g => {
    g.clear()
    g.beginFill(ColorScheme.Color1)
    g.drawRect(0, 0, window.innerWidth, window.innerHeight);
    g.endFill()
  }, [])

  const drawSkillbg = useCallback(g => {
    g.clear()
    g.beginFill(ColorScheme.Color1)
    g.drawRect(0, 0, window.innerWidth, window.innerHeight);
    g.endFill()
  }, [])

  useTick(delta => {
    if (skillPanel_open) {
      targetSkillPanel_x = window.innerWidth - (window.innerWidth / 8);
      targetArrowScale = -1;
      targetPictureBrightness = 0.4;
    } else {
      targetSkillPanel_x = window.innerWidth / 3;
      targetArrowScale = 1;
      targetPictureBrightness = 0.8;
    }
    console.debug(skillPanel_open);

    setSkillPanel_x(skillPanel_x + ((targetSkillPanel_x - skillPanel_x) * delta) * 0.05);
    setArrowScale(arrowScale + ((targetArrowScale - arrowScale) * delta) * 0.1);
    setPictureBrightness(pictureBrightness + ((targetPictureBrightness - pictureBrightness) * delta) * 0.1);

    setSkillPanelContent_x((skillPanel_x) + (window.innerWidth / 3));
  })

  return (
    <Container y={y}>
      <Graphics draw={draw} ref={(ref: any) => setMask(ref)} />
      <Sprite
        image={bgPic}
        scale={{ x: bgPic_scalex, y: bgPic_scaley }}
        anchor={0.5}
        x={bgPic_x}
        y={bgPic_y}
        filters={[colorFilter]}
        mask={mask}
      />
      <Text
        text={AboutmeText.text}
        anchor={0.5}
        x={textX}
        y={textY}
        style={
          new TextStyle({
            align: 'left',
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: textsize,
            fontWeight: '400',
            fill: [ColorScheme.TextColor2],
            wordWrap: true,
            wordWrapWidth: window.innerWidth / 2.5,
            dropShadow: true,
            dropShadowColor: ColorScheme.TextShadow,
            dropShadowAlpha: 1,
            dropShadowBlur: 4,
            dropShadowAngle: Math.PI / 6,
            dropShadowDistance: 2,
          })
        }
      />
      <Text
        text="About"
        anchor={0}
        x={50}
        y={50}
        style={
          new TextStyle({
            align: 'left',
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: window.innerHeight / 12,
            fontWeight: '900',
            fill: [ColorScheme.TextColor2],
            dropShadow: true,
            dropShadowColor: ColorScheme.TextShadow,
            dropShadowAlpha: 0.6,
            dropShadowBlur: 2,
            dropShadowAngle: Math.PI / 6,
            dropShadowDistance: 6,
          })
        }
      />

      <Graphics draw={drawSkillbg}
        position={[skillPanel_x, 0]}
        interactive={true}
        click={() => { setSkillPanel_open(!skillPanel_open) }}
      />
      <ArrowButtonComponent
        x={skillPanel_x}
        y={window.innerHeight / 2}
        size={badge_skill_size * 0.8}
        scale={{ x: arrowScale, y: 1 }}
        click={() => { setSkillPanel_open(!skillPanel_open) }}
      />
      <SkillListComponent array={hardskillstxt} width={window.innerWidth / 2 - 300} height={skillRHeight} x={skillPanelContent_x} y={skillY} altColors={false} />
      <SkillListComponent array={softkillstxt} width={window.innerWidth / 2 - 300} height={skillRHeight} x={skillPanelContent_x} y={skillY2} altColors={true} />

      <ImageBadgeComponent title={'Realistic Dreamer'} x={skillPanelContent_x - (window.innerWidth / 8)} y={badge_skill_y} size={badge_skill_size} image={dreamer_icon} imageScale={{ x: badge_imagesize, y: badge_imagesize }} addNoise={false} />
      <ImageBadgeComponent title={'Team Player'} x={skillPanelContent_x} y={badge_skill_y + badge_skill_size} size={badge_skill_size} image={team_icon} imageScale={{ x: badge_imagesize, y: badge_imagesize }} addNoise={false} />
      <ImageBadgeComponent title={'Creative Thinker'} x={skillPanelContent_x + (window.innerWidth / 8)} y={badge_skill_y} size={badge_skill_size} image={creative_icon} imageScale={{ x: badge_imagesize, y: badge_imagesize }} addNoise={false} />

    </Container>
  );
}