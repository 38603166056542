import { Container, Graphics, Sprite, Text, useTick } from "@inlet/react-pixi"
import { TextStyle } from '@pixi/text';
import { filters, Rectangle } from "pixi.js";
import { useCallback, useState } from "react"
import { ColorScheme } from "../color-scheme"

type Prop = {
  title: string
  size: number
  x: number
  y: number
  image: string,
  imageScale: any
  addNoise: boolean
}

//{title, value, width,height,x,y,altColors =false}:Prop
export const ImageBadgeComponent = ({ title = '', size, x, y, image, imageScale = { x: 1, y: 1 }, addNoise }: Prop) => {

  const frame_startPos_x = 0
  const frame_startPos_y = 0
  const frame_width = size;
  const frame_thicc = frame_width * 0.2;
  const midOffset = -(frame_width * 0.07);

  const midOffset2 = frame_width * 0.7;
  const frame_width2 = frame_width / 2;

  const text_startPos_y = frame_startPos_y + frame_width - midOffset + 15;

  const bwf = new filters.ColorMatrixFilter();

  //bwf.blackAndWhite(true);
  bwf.tint(ColorScheme.TextColor2, true);

  let filtersArray = [bwf, new filters.NoiseFilter(0.3, 50)];
  if (!addNoise)
    filtersArray = [bwf];

  const [currentsize, setCurrentsize] = useState(0);
  const [mouseover, setMouseover] = useState(false);

  const drawBg = useCallback(g => {
    g.clear()

    //background
    g.beginFill(ColorScheme.HighLight2)
    g.lineStyle(1, ColorScheme.HighLight2)
    g.moveTo(frame_startPos_x, frame_startPos_y + frame_width)
    g.lineTo(frame_startPos_x - frame_width, frame_startPos_y)
    g.lineTo(frame_startPos_x, frame_startPos_y - frame_width)
    g.lineTo(frame_startPos_x + frame_width, frame_startPos_y)
    g.lineTo(frame_startPos_x, frame_startPos_y + frame_width)
    g.endFill()
  }, [])

  const draw = useCallback(g => {
    g.clear()

    //front diamond
    g.lineStyle(frame_thicc, ColorScheme.HighLight)
    g.moveTo(frame_startPos_x - midOffset, frame_startPos_y + frame_width)
    g.lineTo(frame_startPos_x - (frame_width + midOffset), frame_startPos_y)
    g.lineTo(frame_startPos_x - midOffset, frame_startPos_y - frame_width)

    g.moveTo(frame_startPos_x + midOffset, frame_startPos_y + frame_width)
    g.lineTo(frame_startPos_x + (frame_width + midOffset), frame_startPos_y)
    g.lineTo(frame_startPos_x + midOffset, frame_startPos_y - frame_width)

    //accents
    g.lineStyle(frame_thicc / 3, ColorScheme.HighLight_alt)
    g.moveTo(frame_startPos_x - midOffset2, frame_startPos_y + frame_width2)
    g.lineTo(frame_startPos_x - (frame_width2 + midOffset2), frame_startPos_y)
    g.lineTo(frame_startPos_x - midOffset2, frame_startPos_y - frame_width2)

    g.moveTo(frame_startPos_x + midOffset2, frame_startPos_y + frame_width2)
    g.lineTo(frame_startPos_x + (frame_width2 + midOffset2), frame_startPos_y)
    g.lineTo(frame_startPos_x + midOffset2, frame_startPos_y - frame_width2)


    g.lineStyle(frame_thicc, ColorScheme.HighLight2)
  }, [])

  const drawBackdrop = useCallback(g => {
    g.clear()
    g.beginFill(ColorScheme.TextColor1)
    g.drawRect(frame_startPos_x - frame_width, text_startPos_y - 11, frame_width * 2, 22);
    g.endFill()
  }, [])

  const [mask, setMask] = useState(null);
  let targetSize = frame_width2;

  useTick(delta => {
    // do something here
    if (mouseover) {
      targetSize = (0.3)
    }
    else {
      targetSize = (0)
    }

    setCurrentsize(currentsize + ((targetSize - currentsize) * delta) * 0.05)
  })

  return (
    <Container position={[x, y]} >
      <Graphics draw={drawBg} />
      <Graphics draw={drawBg} ref={(ref: any) => setMask(ref)} />
      <Sprite
        image={image}
        scale={{ x: imageScale.x * (1 + currentsize), y: imageScale.y * (1 + currentsize) }}
        anchor={0.5}
        x={frame_startPos_x}
        y={frame_startPos_y}
        filters={filtersArray}
        mask={mask}
      />
      <Graphics
        draw={draw}
        interactive={true}
        hitArea={new Rectangle(frame_startPos_x - (frame_width), frame_startPos_y - (frame_width), frame_width * 2, frame_width * 2)}
        mouseover={() => { setMouseover(true) }}
        mouseout={() => { setMouseover(false) }}
      />

      {/* <Graphics draw={drawBackdrop} filters ={[new filters.AlphaFilter(0.5)]} /> */}
      <Text
        text={title}
        anchor={0.5}
        x={frame_startPos_x}
        y={text_startPos_y}
        style={
          new TextStyle({
            align: 'center',
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: 20,
            fontWeight: 'bold',
            fill: [ColorScheme.TextColor1]
          })
        }
      />

    </Container>
  )
}