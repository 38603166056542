import { Container, Stage } from '@inlet/react-pixi';
import { AboutMe } from './pages/about-me/about-me-component';
import { AboutMe2 } from './pages/about-me-2/about-me-2-component';
import { Contact } from './pages/contact/contact-component';
import { Header } from './pages/header/header-component';
import { WorkExperience } from './pages/work-experience/work-experience-compnent';
import { NavBar } from './nav-bar/nav-bar-component';
import './App.css';
import { ColorScheme, ColorSchemeOptions } from './color-scheme';
import {  filters, Rectangle } from 'pixi.js';
import { appendFile } from 'fs';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
const TRACKING_ID = "G-ZRSTREQL7C"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {


  const pageHeight = window.innerHeight;
  const headerHeight = window.innerHeight;
  const numberOfPages = 4;

  ColorSchemeOptions(2);
  const colormatric= new filters.ColorMatrixFilter();
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <header className="App-body">
        {/* <NavBar/> */}
        <Stage width={window.innerWidth} height={headerHeight} options={{ antialias: true, backgroundColor: ColorScheme.Color2 }}>
          <Container filters={[colormatric]}>
            <Header totalHeight={headerHeight + (pageHeight * 1)} y={0} />
          </Container>
        </Stage>
      </header>
      <body>
        <Stage width={window.innerWidth} height={pageHeight} options={{ antialias: true, backgroundColor: ColorScheme.Color2 }}>
          <AboutMe y={0} />
        </Stage>

        <Stage width={window.innerWidth} height={pageHeight} options={{ antialias: true, backgroundColor: ColorScheme.Color2 }}>
          <WorkExperience y={0} />
        </Stage>

        <Stage width={window.innerWidth} height={pageHeight} options={{ antialias: true, backgroundColor: ColorScheme.Color2 }}>
          <Contact y={0} />
        </Stage>
      </body>
    </div>
  );
}

export default App;
