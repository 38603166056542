import { useState } from "react"


const ColorSchemes =[{
    Color1:0xFEF9E7,
    Color1_shade_1:0xE5E0D0,
    Color1_shade_2:0xCCC7B9,
    Color2:0x4c4a45,//DEDBCD
    TextColor1:0x4c4a45,
    TextColor2:0xffffff,
    TextShadow:0x32312e,

    HighLight:0xD89473,
    HighLight2:0xc28567,
    HighLight_alt:0x73B7D8,
    HighLight2_alt:0x67a4c2
},{
    Color1:0xFEF9E7,
    Color1_shade_1:0xE5E0D0,
    Color1_shade_2:0xCCC7B9,
    Color2:0x4c4a45,//DEDBCD
    TextColor1:0x4c4a45,
    TextColor2:0xffffff,
    TextShadow:0x32312e,

    HighLight:0xf55a42,
    HighLight2:0xde503a,
    HighLight_alt:0x16e5f7,
    HighLight2_alt:0x16ccdb
},{
    Color1: 0x323232,
    Color1_shade_1: 0xE5E0D0,
    Color1_shade_2: 0xCCC7B9,
    Color2: 0x191919,
    TextColor1: 0x666666,
    TextColor2: 0x877e91,
    TextShadow: 0x000000,
    HighLight: 0xf2e2cd,
    HighLight2: 0xD9CBB8,
    HighLight_alt: 0xa69eb0,
    HighLight2_alt: 0x958E9E
},{
    Color1: 0x2a2f23,
    Color1_shade_1: 0x252a1f,
    Color1_shade_2: 0x21251c,
    Color2: 0x8b8680,
    TextColor1: 0x666666,
    TextColor2: 0xffffff,
    TextShadow: 0x000000,
    HighLight: 0xe7bd42,
    HighLight2: 0xcfaa3b,
    HighLight_alt: 0x603239,
    HighLight2_alt: 0x4c1920
}];

export const ColorScheme= ColorSchemes[0];

export const ColorSchemeOptions = (i:number) =>{

    const [ColorScheme, setColorScheme] = useState(ColorSchemes[0]);
    return (i:number)=> {
        setColorScheme(ColorSchemes[i]);
    }
}
