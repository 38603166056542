import { Container, Graphics, useTick } from "@inlet/react-pixi"
import { InteractionEvent, Rectangle } from "pixi.js";
import { useCallback, useState } from "react"
import { ColorScheme } from "../color-scheme"

type Prop = {
    size: number
    x: number
    y: number
    rotation?: number | undefined
    scale?: { x: number, y: number } | undefined
    click?: ((event: InteractionEvent) => void) | undefined
}

//{title, value, width,height,x,y,altColors =false}:Prop
export const ArrowButtonComponent = ({ size, x, y, rotation, scale, click }: Prop) => {

    const frame_startPos_x = -(size / 8)
    const frame_startPos_y = 0
    const frame_width = size;
    const frame_thicc = frame_width * 0.2;
    const midOffset = -(frame_width * 0.07);

    const midOffset2 = frame_width * 0.7;
    const frame_width2 = frame_width / 2;

    const [currentsize, setCurrentsize] = useState(0);
    const [mouseover, setMouseover] = useState(false);

    const drawBg = useCallback(g => {
        g.clear()

        //background
        g.beginFill(ColorScheme.HighLight2)
        g.lineStyle(1, ColorScheme.HighLight2)
        g.moveTo(frame_startPos_x, frame_startPos_y + frame_width)
        // g.lineTo(frame_startPos_x-frame_width,frame_startPos_y)
        g.lineTo(frame_startPos_x, frame_startPos_y - frame_width)
        g.lineTo(frame_startPos_x + frame_width, frame_startPos_y)
        g.lineTo(frame_startPos_x, frame_startPos_y + frame_width)
        g.endFill()
    }, [])

    const draw = useCallback(g => {
        g.clear()

        //front diamond
        g.lineStyle(frame_thicc, ColorScheme.HighLight)

        g.moveTo(frame_startPos_x + midOffset, frame_startPos_y + frame_width)
        g.lineTo(frame_startPos_x + (frame_width + midOffset), frame_startPos_y)
        g.lineTo(frame_startPos_x + midOffset, frame_startPos_y - frame_width)

        //accents
        g.lineStyle(frame_thicc / 3, ColorScheme.HighLight_alt)

        g.moveTo(frame_startPos_x + midOffset2, frame_startPos_y + frame_width2)
        g.lineTo(frame_startPos_x + (frame_width2 + midOffset2), frame_startPos_y)
        g.lineTo(frame_startPos_x + midOffset2, frame_startPos_y - frame_width2)

        g.lineStyle(frame_thicc, ColorScheme.HighLight2)
    }, [])

    let targetSize = frame_width2;

    useTick(delta => {
        // do something here
        if (mouseover) {
            targetSize = (0.3)
        }
        else {
            targetSize = (0)
        }

        setCurrentsize(currentsize + ((targetSize - currentsize) * delta) * 0.05)
    })

    return (
        <Container
            position={[x, y]}
            rotation={rotation}
            scale={scale}
        >
            <Graphics
                draw={drawBg}
                scale={{ x: (1 + currentsize), y: (1 + currentsize) }}
            />

            <Graphics
                draw={draw}
                interactive={true}
                hitArea={new Rectangle(frame_startPos_x - (frame_width), frame_startPos_y - (frame_width), frame_width * 2, frame_width * 2)}
                scale={{ x: (1 + currentsize), y: (1 + currentsize) }}
                click={click}
                mouseover={() => { setMouseover(true) }}
                mouseout={() => { setMouseover(false) }}
            />

        </Container>
    )
}