import { Container } from "@inlet/react-pixi"
import { SkillBarComponent } from "./skill-bar-component";

type Prop = {
    width: number
    height: number
    x: number
    y: number
    array:any[]
    altColors:boolean
}

export const SkillListComponent = ({ array,width, height, x, y,altColors }: Prop) => {

    const rowheight = height+2;
    const listLength = array.length*rowheight;
    const startingPoint = (y-listLength/2);
    array.sort((n1,n2) => n2.value - n1.value);
    const list = array.map((skill,index) => <SkillBarComponent key = {skill.label} title={skill.label} value={skill.value/10} width={width} height={height} x={x} y={startingPoint+(index*rowheight)} altColors = {altColors} />)

    return (
        <Container>
            {list}
        </Container>
    )
}