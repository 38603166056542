
import { Container, Sprite, Text, useTick } from '@inlet/react-pixi';
import { TextStyle } from '@pixi/text';
import { Texture, BaseTexture, SCALE_MODES } from 'pixi.js';
import { useCallback, useState } from 'react';
import { ColorScheme } from '../../color-scheme';
import { DrawCubeComponent } from '../../components/draw-cube-component';
import spread_img from './Spread_eye.png'
import stand_img_2 from './Website_Person_Standing.png'
import backdrop_back_img from './Website_Background _Backdrop.png'
import backdrop_front_img from './Website_Background _frontdrop.png'

type Prop = {
  totalHeight: number
  y: number
}

export const Header = ({ totalHeight, y }: Prop) => {

  const [timePerc, setTimePerc] = useState(1);
  const [blurCurAmount, setBlurCurAmount] = useState(0);

  const duration = 100;
  const blurAmount = 1;
  useTick(delta => {

    const i = delta;
    if (timePerc < duration) {
      setTimePerc(timePerc + i);
    } else {
      setTimePerc(duration);
    }
    setBlurCurAmount(blurAmount - (blurAmount * (timePerc / duration)));
  })
  //  const draw = useCallback(g => {
  //   g.clear()
  //   g.beginFill(ColorScheme.Color1)
  //   g.moveTo(0, window.innerHeight/4)
  //   g.lineTo(window.innerWidth/2, window.innerHeight)
  //   g.lineTo(0, window.innerHeight)
  //   g.lineTo(0, window.innerHeight/4)
  //   g.endFill()
  // }, [])

  const drawSquare = useCallback(g => {
    g.clear()
    g.beginFill(ColorScheme.Color1)
    g.drawRect(0, 0, window.innerWidth, window.innerHeight)
    g.endFill()

  }, [])

  const drawSquare2 = useCallback(g => {
    g.clear()
    g.beginFill(ColorScheme.Color1)
    g.drawRect(0, window.innerHeight * 0.9, window.innerWidth, window.innerHeight * 0.2)
    g.endFill()

  }, [])

  const drawBackdrop2 = useCallback(g => {

    g.clear()
    g.beginFill(ColorScheme.Color2)
    g.drawRect(0, window.innerHeight, window.innerWidth, totalHeight - window.innerHeight);

    g.endFill()
  }, [])


  const hexToRgb = (hex: number) => {
    var r = (hex >> 16) & 255;
    var g = (hex >> 8) & 255;
    var b = hex & 255;

    return { x: r, y: g, z: b };
  }
  const gradient = (color: number, width: number, height: number) => {

    const rgb = hexToRgb(color);

    const c = document.createElement("canvas");
    c.width = width
    c.height = height
    const ctx = c.getContext("2d");
    if (ctx == null)
      return;
    const grd = ctx.createLinearGradient(0, 0.2, 0, height * 0.7);

    grd.addColorStop(1, `rgba(${rgb.x}, ${rgb.y}, ${rgb.z}, 1)`);
    grd.addColorStop(0, `rgba(${rgb.x}, ${rgb.y}, ${rgb.z}, 0)`);
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, width, height);

    return Texture.from(c);
  }

  const tower_y = window.innerHeight - 150;
  const totalTower_Height = totalHeight - tower_y;
  const topTowerSize = [window.innerWidth / 8, totalTower_Height * 0.5];
  const topTowerPos = [window.innerWidth / 4, tower_y];

  const midTowerSize = [topTowerSize[0] * 1.5, totalTower_Height * 0.3];
  const midTowerPos = [topTowerPos[0], topTowerPos[1] + topTowerSize[1]];

  const botTowerSize = [midTowerSize[0] * 1.5, totalTower_Height * 0.2];
  const botTowerPos = [topTowerPos[0], midTowerPos[1] + midTowerSize[1]];

  const fontsize = window.innerWidth / 25;
  const x_pos = 50;
  const y_pos = window.innerHeight / 2 + window.innerHeight / 4;


  const stand_img_texture = new Texture(
    new BaseTexture(stand_img_2, { scaleMode: SCALE_MODES.NEAREST })
  );
  const spread_img_texture = new Texture(
    new BaseTexture(spread_img, { scaleMode: SCALE_MODES.NEAREST })
  );

  const backdrop_back_tex = new Texture(
    new BaseTexture(backdrop_back_img, { scaleMode: SCALE_MODES.NEAREST })
  );

  const backdrop_front_tex = new Texture(
    new BaseTexture(backdrop_front_img, { scaleMode: SCALE_MODES.NEAREST })
  );
  const backdropsize = (window.innerWidth / backdrop_front_tex.width);
  const imagesize = (window.innerWidth / backdrop_front_tex.width);
  const imagesize_2 = ((1 / 1440) * window.innerHeight) * 2;

  return (
    <Container position={[0, y]} filters={[/*new filters.BlurFilter(blurCurAmount),*/]}>
      {/* <Graphics draw={draw}/> */}
      {/* <Sprite 
              texture={spread_img_texture}
              scale={{x:imagesize_2,y:imagesize_2}}
              anchor={0.5}
              x={window.innerWidth/2}
              y={window.innerHeight/4}
            /> */}

      <Sprite
        texture={backdrop_back_tex}
        scale={{ x: backdropsize, y: backdropsize }}
        anchor={0.5}
        x={window.innerWidth / 2}
        y={window.innerHeight / 2}
      />
      <Sprite
        texture={backdrop_front_tex}
        scale={{ x: backdropsize, y: backdropsize }}
        anchor={0.5}
        x={window.innerWidth / 2}
        y={window.innerHeight / 2}
      />
      <Sprite
        texture={gradient(ColorScheme.Color2, window.innerWidth, window.innerHeight * 0.1)}
        y={window.innerHeight}
      />
      {/* <Graphics draw={drawSquare2} filters = {[FadeFilter({color:ColorScheme.HighLight,transitionValue:blurCurAmount})]}/> */}


      <DrawCubeComponent width={botTowerSize[0]} height={botTowerSize[1]} x={botTowerPos[0]} y={botTowerPos[1]} />
      <DrawCubeComponent width={midTowerSize[0]} height={midTowerSize[1]} x={midTowerPos[0]} y={midTowerPos[1]} />
      <DrawCubeComponent width={topTowerSize[0]} height={topTowerSize[1]} x={topTowerPos[0]} y={topTowerPos[1]} />
      <Sprite
        texture={stand_img_texture}
        scale={{ x: imagesize, y: imagesize }}
        anchor={0.5}
        x={topTowerPos[0] - (15 * imagesize)}
        y={topTowerPos[1] - (70 * imagesize)}
      />

      <Sprite
        texture={gradient(ColorScheme.Color2, window.innerWidth, totalHeight)}
        y={window.innerHeight}
      />
      {/* <Graphics draw={drawBackdrop2} filters = {[new filters.AlphaFilter(0.4)]}/> */}
      <Text
        text="STIJN"
        anchor={0}
        x={x_pos}
        y={y_pos}
        style={
          new TextStyle({
            align: 'left',
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: fontsize,
            fontWeight: '900',
            fill: [ColorScheme.TextColor2],
            dropShadow: true,
            dropShadowColor: ColorScheme.TextShadow,
            dropShadowAlpha: 0.6,
            dropShadowBlur: 2,
            dropShadowAngle: Math.PI / 6,
            dropShadowDistance: 6,
          })
        }
      />
      <Text
        text="GROOTHUIS"
        anchor={0}
        x={x_pos}
        y={y_pos + fontsize}
        style={
          new TextStyle({
            align: 'left',
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: fontsize,
            fontWeight: '900',
            fill: [ColorScheme.TextColor2],
            dropShadow: true,
            dropShadowColor: ColorScheme.TextShadow,
            dropShadowAlpha: 0.6,
            dropShadowBlur: 2,
            dropShadowAngle: Math.PI / 6,
            dropShadowDistance: 6,
          })
        }
      />
      <Text
        text="Fullstack developer"
        anchor={0}
        x={x_pos + 15}
        y={y_pos + fontsize + fontsize + 5}
        style={
          new TextStyle({
            align: 'left',
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: fontsize / 3,
            fontWeight: 'bold',
            fill: [ColorScheme.HighLight],
            wordWrap: true,
            wordWrapWidth: 800,
            dropShadow: false,
            dropShadowColor: ColorScheme.TextShadow,
            dropShadowAlpha: 1,
            dropShadowBlur: 4,
            dropShadowAngle: Math.PI / 6,
            dropShadowDistance: 2,
          })
        }
      />

      {/* <Graphics draw={drawSquare} filters ={[BrickTestFilter({colorLeft:ColorScheme.Color1,colorRight:ColorScheme.Color2,transitionValue:blurCurAmount})]}/> */}
    </Container>
  );
}