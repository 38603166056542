import { Container, Graphics, Text } from '@inlet/react-pixi';
import { TextStyle } from '@pixi/text';
import { useCallback } from 'react';
import { ColorScheme } from '../../color-scheme';

type Prop = {
  y: number
}

export const Contact = ({ y }: Prop) => {
  const draw = useCallback(g => {
    g.clear()
    g.beginFill(ColorScheme.Color1)
    g.moveTo(0, 0)
    g.lineTo(window.innerWidth, 0)
    g.lineTo(window.innerWidth / 2, window.innerHeight / 4)
    g.lineTo(0, 0)
    g.endFill()
  }, [])
  const draw2 = useCallback(g => {
    g.clear()
    g.beginFill('black')
    g.moveTo(0, window.innerHeight / 4)
    g.lineTo(window.innerWidth / 2, window.innerHeight)
    g.lineTo(window.innerWidth, window.innerHeight / 4)
    g.lineTo(window.innerWidth, window.innerHeight)
    g.lineTo(0, window.innerHeight)
    g.lineTo(0, window.innerHeight / 4)
    g.endFill()
  }, [])

  const draw3 = useCallback(g => {
    g.clear()
    g.beginFill(ColorScheme.Color2)
    g.drawRect(0, 0, window.innerWidth, window.innerHeight);
    g.endFill()
  }, [])
  return (
    <Container y={y} >
      <Graphics draw={draw3} />
      {/* <Graphics draw={draw} />
        <Graphics draw={draw2} /> */}
      {/* <DrawCubeComponent width = {window.innerWidth/2} height = {(window.innerWidth/4)} x = {window.innerWidth/2} y = {window.innerHeight-(window.innerWidth*0.1875)} />
        <DrawCubeComponent width = {window.innerWidth*0.375} height = {window.innerHeight+(window.innerWidth*0.375/2)} x = {window.innerWidth/2} y = {-window.innerWidth*0.375} /> */}
      <Text
        text="Contact"
        anchor={0}
        x={50}
        y={50}
        style={
          new TextStyle({
            align: 'left',
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: window.innerHeight / 12,
            fontWeight: '900',
            fill: [ColorScheme.TextColor2],
            dropShadow: true,
            dropShadowColor: ColorScheme.TextShadow,
            dropShadowAlpha: 0.6,
            dropShadowBlur: 2,
            dropShadowAngle: Math.PI / 6,
            dropShadowDistance: 6,
          })
        }
      />
      <Text
        text="In development"
        anchor={0.5}
        x={window.innerWidth / 2}
        y={window.innerHeight / 2}
        style={
          new TextStyle({
            align: 'left',
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: 25,
            fontWeight: '400',
            fill: [ColorScheme.TextColor2],
            wordWrap: true,
            wordWrapWidth: 1200,
          })
        }
      />
    </Container>
  );
}