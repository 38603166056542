
import { Container, Graphics, Text } from '@inlet/react-pixi';
import { TextStyle } from '@pixi/text';
import { useCallback } from 'react';
import { ColorScheme } from '../../color-scheme';
import { DrawCubeComponent } from '../../components/draw-cube-component';

type Prop = {
  y: number
}

export const WorkExperience = ({ y }: Prop) => {

  const draw = useCallback(g => {
    g.clear()
    g.beginFill(ColorScheme.Color1)
    g.drawRect(0, 0, window.innerWidth, window.innerHeight);
    g.endFill()
  }, [])
  return (
    <Container y={y} >
      <Graphics draw={draw} />
      {/* <DrawCubeComponent width = {window.innerWidth*0.375} height = {window.innerHeight} x = {window.innerWidth/2} y = {0} />
          <DrawCubeComponent width = {window.innerWidth/4} height = {window.innerWidth/4} x = {window.innerWidth/2} y = {-window.innerWidth/4} /> */}

      <Text
        text="Work"
        anchor={0}
        x={50}
        y={50}
        style={
          new TextStyle({
            align: 'center',
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: window.innerHeight / 12,
            fontWeight: '900',
            fill: [ColorScheme.TextColor2],
            dropShadow: true,
            dropShadowColor: ColorScheme.TextShadow,
            dropShadowAlpha: 0.6,
            dropShadowBlur: 2,
            dropShadowAngle: Math.PI / 6,
            dropShadowDistance: 6,
          })
        }
      />
      <Text
        text="In development"
        anchor={0.5}
        x={window.innerWidth / 2}
        y={window.innerHeight / 2}
        style={
          new TextStyle({
            align: 'left',
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: 25,
            fontWeight: '400',
            fill: [ColorScheme.TextColor1],
            wordWrap: true,
            wordWrapWidth: 1200,
          })
        }
      />
    </Container>
  );
}