export const AboutmeText = {
    text:`Hallo, I'm a full stack developer located in the Netherlands that does Game development on the side. 
I have experience in making backend API systems in Azure cloud and corresponding front end applications in Angular or React. 

I love to find out the possibilities and pushing bounderies of technology. 
With Game development I love to make generative and unique systems. 
It makes you question the experience of human interaction and how to create technologies that can accommodate that. 
I love the creative thinking and delivering the best user experience in all of my work.

I'm a realistic dreamer that loves to think about the impossible, breaking it down to attainable chunks and working with passionate people to make is see into fruition.
I'm a team player that doesn't run away from a challenge, is critical and willing to ask the hard questions inorder to make the best possible product.
I'm a creative thinker that loves to hear people's passion and make their dreams come through. 

So who is willing to go on an adventure with me!`
}