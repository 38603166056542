import { Container, Graphics } from "@inlet/react-pixi"
import { useCallback } from "react"
import { ColorScheme } from "../color-scheme"

type Prop = {
    width:number
    height:number
    x:number
    y:number
}

//{title, value, width,height,x,y,altColors =false}:Prop
export const DrawCubeComponent=({width,height, x,y}:Prop)=>{
    
  const frame_startPos_x = x
  const frame_startPos_y = y
  const frame_width = width;
  const frame_height = width/4;
  
  const draw = useCallback(g => {
    g.clear()

    //Top
    g.beginFill(ColorScheme.Color1)
    g.lineStyle(1,ColorScheme.Color1)
    g.moveTo(frame_startPos_x,frame_startPos_y+frame_height)
    g.lineTo(frame_startPos_x-frame_width,frame_startPos_y)
    g.lineTo(frame_startPos_x,frame_startPos_y-frame_height)
    g.lineTo(frame_startPos_x+frame_width,frame_startPos_y)
    g.lineTo(frame_startPos_x,frame_startPos_y+frame_height)
    g.endFill()


    g.beginFill(ColorScheme.Color1_shade_2)
    g.lineStyle(1,ColorScheme.Color1_shade_2)
    g.moveTo(frame_startPos_x+frame_width,frame_startPos_y)
    g.lineTo(frame_startPos_x,frame_startPos_y+frame_height)
    g.lineTo(frame_startPos_x,frame_startPos_y+frame_height+height)
    g.lineTo(frame_startPos_x+frame_width,frame_startPos_y+height)
    g.lineTo(frame_startPos_x+frame_width,frame_startPos_y)
    g.endFill()

    
    g.beginFill(ColorScheme.Color1_shade_1)
    g.lineStyle(1,ColorScheme.Color1_shade_1)
    g.moveTo(frame_startPos_x-frame_width,frame_startPos_y)
    g.lineTo(frame_startPos_x,frame_startPos_y+frame_height)
    g.lineTo(frame_startPos_x,frame_startPos_y+frame_height+height)
    g.lineTo(frame_startPos_x-frame_width,frame_startPos_y+height)
    g.lineTo(frame_startPos_x-frame_width,frame_startPos_y)
    g.endFill()


  }, [])

    return(
        <Container>
            <Graphics draw ={draw}/>
        </Container>
    )
}