import { Container, Graphics, Text } from "@inlet/react-pixi"
import { TextStyle } from '@pixi/text';
import { useCallback } from "react"
import { ColorScheme } from "../color-scheme"

type Prop = {
  title: string
  value: number
  width: number
  height: number
  x: number
  y: number
  altColors: boolean
}

export const SkillBarComponent = ({ title, value, width, height, x, y, altColors = false }: Prop) => {
  const textHeight = height * 0.40;

  const pos_x = x - width / 2;
  const pos_y = y - height / 2;

  const width_Head = height * 4;

  const valueOffset = 0;
  const width_fullprec = width - width_Head - valueOffset
  const width_value = width_fullprec * value;

  const draw = useCallback(g => {
    g.clear()
    //bg
    g.beginFill(ColorScheme.TextShadow)
    g.drawRect(width_Head / 2, 0, width - width_Head / 2, height);
    g.endFill()
    //Value bar
    //bg
    g.beginFill(0x706D66)
    g.drawRect(width_Head - 15, valueOffset, width_fullprec + 15, height - (valueOffset * 2));
    g.endFill()
    //value
    g.beginFill(altColors ? ColorScheme.HighLight2_alt : ColorScheme.HighLight2)
    g.drawRect(width_Head - 15, valueOffset, width_value + 15, height - (valueOffset * 2));
    g.endFill()
    //headbg
    g.beginFill(altColors ? ColorScheme.HighLight_alt : ColorScheme.HighLight)
    g.drawRect(0, 0, width_Head, height);
    g.endFill()

    // //centercircle
    // g.beginFill(0xFF0000)
    // g.drawCircle(x, y, 2);
    // g.endFill()
  }, [])

  return (
    <Container position={[pos_x, pos_y]}>
      <Graphics draw={draw} />
      <Text
        text={title}
        anchor={0.5}
        x={width_Head / 2}
        y={height / 2}
        style={
          new TextStyle({
            align: 'center',
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: textHeight,
            fontWeight: 'bold',
            fill: [ColorScheme.TextColor2]
          })
        }
      />
      <Text
        text={(value * 100).toString() + '%'}
        anchor={0.5}
        x={width - 20}
        y={height / 2}
        style={
          new TextStyle({
            align: 'center',
            fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
            fontSize: textHeight,
            fontWeight: 'bold',
            fill: [ColorScheme.TextColor2]
          })
        }
      />
    </Container>
  )
}